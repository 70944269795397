import { createRouter, createWebHashHistory } from 'vue-router';
const AppContainer = () => import('@/container/AppContainer')
//const TkyLogin = () => import('@/pages/login/TkyLogin')
const TkyAuthMain = () => import('@/pages/login/TkyAuthMain')
//const TkyResetPwd = () => import('@/pages/login/TkyResetPwd')
import { h, resolveComponent } from 'vue'
import { store } from '@/store'
//import firebase from '@/firebase'
import { getToken } from '@/utils/auth'

const constantRoutes = [
  /*
    {
      path: '/redirect',
      hidden: true,
      children: [
        {
          path: '/redirect/:path(.*)',
          component: () => import('@/pages/redirect/index')
        }
      ]
    },*/
      {
        path: '/',
        redirect: '/home',
        name: 'Home',
        component: AppContainer,
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/pages/rain/InsRainMain.vue'),
            },
            {
                path: '/mgroup',
                name: 'mgroup',
                component: () => import('@/pages/master/MastGroupMain.vue'),
              },
            {
                path: '/mcust',
                name: 'mcust',
                component: () => import('@/pages/master/MastCustMain.vue'),
            },
            {
                path: '/muser',
                name: 'muser',
                component: () => import('@/pages/master/MastUserMain.vue'),
              },
            {
              path: '/mdept',
              name: 'mdept',
              component: () => import('@/pages/master/MastOrgMain.vue'),
            },
            {
              path: '/msales',
              name: 'msales',
              component: () => import('@/pages/master/MastSalesMain.vue'),
            },
            {
              path: '/appl',
              name: 'appl',
              component: () => import('@/pages/appl2/ApplRainInsMain.vue'),
//              component: () => import('@/pages/appl/ApplRainMain.vue'),
//              component: () => import('@/pages/appl/ApplRainMain.vue'),
            },
      ]
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render() {
              return h(resolveComponent('router-view'))
            }
        },
        children: [
          {
            path: '404',
            name: 'Page404',
            component:  () => import('@/pages/login/TkyLogin'),
          },
          {
            path: '500',
            name: 'Page500',
            component: TkyAuthMain
          },
          {
            path: 'login',
            name: 'Login',
            component: TkyAuthMain
          }
        ]
    },
/*    {
      path: '/auth-redirect',
      component: () => import('@/pages/login/auth-redirect'),
      hidden: true
    },
*/    // 404 page must be placed at the end !!!
    { path: '/:catchAll(.*)', redirect: '/pages/404', hidden: true }
];
/*
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
*/
const createCustomRouter = () => createRouter({
  // mode: 'history', // require service support
  history: createWebHashHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
/*
router.beforeEach(function(to, from, next) {
  window.scrollTo(0, 0);
  next();
});
*/
const router = createCustomRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createCustomRouter()
  router.matcher = newRouter.matcher // reset router
}

// --------------------
// ROUTING
const whiteList = ['/pages/login', '/auth-redirect'] // no redirect whitelist
/* 機能していないので省きました
// Listening for auth state changes.
firebase.auth().onAuthStateChanged(function(user) {
  console.log("onAuthStateChanged")
  if (user) {
    // User is signed in.
    store.dispatch('user/getInfo')
    router.push({ path: '/' })
  } else {
    // self.loginButtonText = 'Sign in with Google'
    store.dispatch('user/logout')
    router.push({ path: '/pages/login' })
  }
})
*/
router.beforeEach(async(to, from, next) => {
  // determine whether the user has logged in
  //console.log(to.path + "/" + from.path)
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/pages/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        next()
//        next({ ...to, replace: true })
        /*
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          //const { roles } = ['admin'] //await store.dispatch('user/getInfo')

          // generate accessible routes map based on roles
          //const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // dynamically add accessible routes
          //router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          //Message.error(error || 'Has Error')
          next(`/pages/login?redirect=${to.path}`)
          // NProgress.done()
        }
        */
      }
    }
  } else {
    // has no token 
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/pages/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  // NProgress.done()
})

export default router
