//import firebase from 'firebase/app'
//import 'firebase/auth'
import axios from 'axios'
//import qs from 'qs'
//import { getToken, setToken, removeToken } from '@/utils/auth'
import { setToken } from '@/utils/auth'
import { getPerm, setPerm } from './utils/auth';

/*
const firebaseConfig = {
  apiKey: "AIzaSyAIxLtDjrjYZ7CGt24L2dmECxGrOAwpe5k",
  authDomain: "jpn-bts-dev.firebaseapp.com",
  projectId: "jpn-bts-dev",
  storageBucket: "jpn-bts-dev.appspot.com",
  messagingSenderId: "534860876127",
  appId: "1:534860876127:web:a94afbb7bb8b05e6d70eea"
};
*/
//firebase.initializeApp(firebaseConfig)

//const amewari_login_url = "https://m2.takugi.net/jins_kawa/";

//----------------------------------------------------
// カレントユーザクラス
//----------------------------------------------------
class cUser {
  constructor() {
    this.email = "";
    this.user = "default";
    this.idToken = "token";
    this.emailVerified = "";
    this.displayName = "Nuu";
  }

  /**
   * @param {string} token
   */
  set setIdToken(token) {
    this.idToken = token;
  }


  getIdToken2() {
    return this.idToken;
  }
  get getUser() {
    return this.user;
  }
  /**
   * @param {string} user
   */
  set setUser(user) {
    this.user = user;
  }

  getIdToken(isfresh) {
    //console.log("getIdToken:"+isfresh);
    var id = this.idToken;
    return new Promise(function(resolve, reject) {
      if(isfresh==false) {
        resolve(id);
      } else {
        reject(id+"_err");
      }
    });
  }

  getIdTokenResult(isfresh) {
    //console.log("getIdTokenResult:"+isfresh);
    var id = this.idToken;
    return new Promise(function(resolve, reject) {
      if(isfresh==false) {
        resolve(id);
      } else {
        reject(id+"_err");
      }
    });
  }

}

//====================================================
// 認証クラス
//====================================================
class AuthFirebaseClass {
  constructor() {
    this.mycUser = new cUser();
  }

  onAuthStateChanged(val) {
    console.log(val);
  }

  //----------------------------------------------------
  // ログイン
  //----------------------------------------------------
  signInWithEmailAndPassword(mail, pwd) {
    var sUser = this.mycUser;
    return new Promise((resolve , reject) =>{
      // ここでログイン処理を行う
      var url = process.env.VUE_APP_SERVER_API + '/login';
      const data = {'name': pwd , 'mail': mail};
      axios({
        method : 'post',
        url    : url,
        headers: { 
          'content-type': 'application/json;charset=UTF-8', 
          'accept': 'application/json'
        },
        data: data,
//        data: qs.stringify(data),
      })
      .then(function(response) {
        //console.log(response.data);
        var data = response.data;
        switch(data.md) {
          case 0:// 成功
            sUser.email= data.ss;
            sUser.user = data.mc;
            sUser.idToken = data.mn;
            sUser.displayName = data.sn;
            sUser.emailVerified = '';
            setToken(data.mn);
            setPerm(sUser);
            resolve(sUser);
            break;
          default:// エラー
            var error = {
              code: data.sc,
              message: data.sn
            };
            reject(error);
            break;
        }
      })
      .catch(function (error) {
        console.log(error);
        var err = {
          code: "",
          message: ""
        };
        reject(err);
      })
      .finally(function(){
      });
    });
  }

  //----------------------------------------------------
  // ユーザー作成
  //----------------------------------------------------
  //createUserWithEmailAndPassword(mail, pwd) {
  createUserWithEmailAndPassword() {
    //console.log("createUserWithEmailAndPassword Mail:"+mail + " PW:"+pwd);
    return new Promise((resolve , reject) =>{
      var isTrue = true;
      if(isTrue) {
        resolve(this.cUser);
      } else {
        this.cUser.user = "クリエイトエラー";
        reject(this.cUser)
      }
      //return this.cUser;
    });
  }

  //----------------------------------------------------
  // ログアウト
  //----------------------------------------------------
  signOut() {
    var sUser = this.mycUser;
    //console.log("サインアウト User:"+sUser.user + " PW:"+sUser.idToken);

    return new Promise((resolve , reject) =>{
      var url = process.env.VUE_APP_SERVER_API + '/logout';
//      const data = {'token': sUser.idToken};
      axios({
        method : 'post',
        url    : url,
        headers: {
           'content-type': 'application/json;charset=UTF-8', 
           'accept': 'application/json',
           'EE-Token': sUser.idToken
          },
//        data: data,
      })
      .then(function(response) {
        //console.log(response.data);
        var data = response.data;
        switch(data.md) {
          case 0:// 成功
          case 1:// 成功
            sUser.email = "";
            sUser.user = "";
            sUser.idToken = "";
            sUser.emailVerified = "";
            sUser.displayName = "NAA";
            //removeToken();
            //var t = getToken();
            //console.log("removeToken->:"+t);
            resolve(sUser);
            break;
          default:// エラー
            var error = {
              code: data.sc,
              message: data.sn
            };
            reject(error);
            break;
        }
      })
      .catch(function (error) {
        console.log(error);
        var err = {
          code: "",
          message: ""
        };
        reject(err);
      })
      .finally(function(){
      });
    });
  }


  /**
   * カレントユーザー取得
   */
  get currentUser() {
    //console.log('HERE!');
    if(this.mycUser == null){
      this.mycUser = getPerm()
    //}else{
      //let a = getPerm()
      //console.log(a)
    }
    return this.mycUser;
  }

  /**
   * @param {cUser} cuser
   */
  set currentUser(cuser) {
    this.mycUser = cuser;
  }
 
}


//----------------------------------------------------
// Firebaseエミュレートクラス
//----------------------------------------------------
class FirebaseClass {
  constructor() {
//    this.config = firebaseConfig;
    this.authBase = new AuthFirebaseClass();
  }

/*
  get getConfig() {
    return this.config;
  }
*/ 
  /**
   * @param {{ apiKey: string; authDomain: string; projectId: string; storageBucket: string; messagingSenderId: string; appId: string; }} val
  set setConfig(val) {
    this.config = val;
  }
   */

  auth() {
    return this.authBase;

  }
}

const firebase = new FirebaseClass();

export default firebase