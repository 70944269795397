export const getters = {
  view_cur: state => state.app.view_cur,
  view_back_flag: state => state.app.view_back_flag,
  title: state => state.app.title,

  rain_date_obj: state => state.rain.target_date_1,
  rain_date_str: state => state.rain.target_date_2,
  rain_date_num: state => state.rain.target_date_3,
  rain_max_date: state => state.rain.max_date,
  rain_min_date: state => state.rain.min_date,
  rain_gc: state => state.rain.gc,
  rain_result: state => state.rain.result,
  rain_time: state => state.rain.time,
  rain_pos: state => state.rain.pos,
  rain_ci: state => state.rain.ci,

  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  email: state => state.user.email,

  menu: state => state.role.menu,
  role: state => state.role.role,

  ins_date_obj: state => state.insurance.target_date_1,
  ins_date_str: state => state.insurance.target_date_2,
  ins_date_num: state => state.insurance.target_date_3,
  ins_month: state => state.insurance.target_month,

//  introduction: state => state.user.introduction,
//  roles: state => state.user.roles,
//  permission_routes: state => state.permission.routes,
//  errorLogs: state => state.errorLog.logs
}
