//import { createStore } from 'vuex'

const state = {
    view_cur: '',
    view_back: '',
    view_back_flag: false,
    title: ''
}
const mutations = {
  SET_TITLE (state, title) {
    state.title = title
  },
  SET_VIEW_CUR (state, viewc) {
    state.view_cur = viewc
  },
  SET_VIEW_BACK (state, viewb) {
    state.view_back = viewb
  },
  SET_VIEW_BACK_FLAG (state, bol) {
    state.view_back_flag = bol
  }
}
const actions = {
  transScreen({ commit }, {curr, back}) {
    commit('SET_VIEW_CUR', curr)
    commit('SET_VIEW_BACK', back)
    commit('SET_VIEW_BACK_FLAG', back == '' ? false : true)
  },
  transBack({ commit }) {
    //console.log(state.view_back)
		commit("SET_VIEW_CUR", state.view_back)
		commit("SET_VIEW_BACK", '')
    commit('SET_VIEW_BACK_FLAG', false)
  },
  transClear({ commit }) {
    //console.log(state.view_back)
		commit("SET_VIEW_CUR", '')
		commit("SET_VIEW_BACK", '')
    commit('SET_VIEW_BACK_FLAG', false)
  },
  setTitle({ commit }, title) {
    commit('SET_TITLE', title)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
