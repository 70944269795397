// import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, getName, getEmail } from '@/utils/auth'
import router, { resetRouter } from '@/router'
//import firebase from 'firebase/app'
//import 'firebase/auth'
import firebase from '@/firebase'
import { setPerm } from '../../utils/auth'

const state = {
  token: getToken(),
  name: getName(),
  avatar: '',
  email: getEmail(),
  verified: false,
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
//    console.log(token)
    state.token = token
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_VERIFIED: (state, verified) => {
    state.verified = verified
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // Firebase Version
  // user login
  login({ commit }, userInfo) {
    //console.log("XXX")
    const { user, pwd } = userInfo
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(user.trim(), pwd)
      .then(response => {
        // 成功時の処理
        // response.user.tenantId should be ‘TENANT_PROJECT_ID’.
        console.log(response.user)
        var cuu = firebase.auth().currentUser;
        //console.log(cuu)
        firebase.auth().currentUser.getIdToken(/* forceRefresh */false)
        .then(function (idToken) {
          // Send token to your backend via HTTPS
//          console.log(idToken)
          commit('SET_TOKEN', idToken)
          commit('SET_EMAIL', cuu.email);
          commit('SET_NAME', cuu.displayName)
          commit('SET_VERIFIED', cuu.emailVerified)
          setToken(idToken)
          setPerm(cuu)
        })
        .catch(function (error) {
          // Handle error
          console.log(error)
        })
        resolve()
      }).catch(error => {
        // エラー時の処理 
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === 'auth/invalid-email') {
          reject('メールアドレスが不正です');
        } else if (errorCode === 'auth/user-disabled') {
          reject('ユーザーが無効になっています');
        } else if (errorCode === 'auth/user-not-found') {
          reject('ユーザーが登録されていません');
        } else if (errorCode === 'auth/wrong-password') {
          reject('パスワードが不正です');
        } else {
          reject(errorMessage);
        }
      })
    })
  },
  // Firebase Version
  // get user info
  updateToken({ commit }, token) {
      commit('SET_TOKEN', token)
      setToken(token)
  },

  // Firebase Version
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser
      if (user != null) {
        const roles = ['admin']
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        //commit('SET_TOKEN', user.getToken)
        //setToken(user.getToken)

        commit('SET_ROLES', roles)
        commit('SET_NAME', user.displayName)
        //commit('SET_AVATAR', user.photoURL == null ? 'https://m1.takugi.net/kara/iconfinder_unknown_1_628286.png' : user.photoURL)
        commit('SET_EMAIL', user.email)
        commit('SET_VERIFIED', user.emailVerified)
        resolve({ roles: roles })
      } else {
        reject('Verification failed, please Login again!')
      }
    })
  },

  // Firebase Version
  // get user info
  setInfo({ commit }, user) {
    commit('SET_NAME', user.displayName)
    commit('SET_EMAIL', user.email)
    commit('SET_VERIFIED', user.emailVerified)
  },
  // Firebase Version
  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resetRouter()
      const user = firebase.auth().currentUser
      if (user != null) {
        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        //dispatch('tagsView/delAllViews', null, { root: true })
        router.push({ path: '/pages/login' })
        firebase.auth().signOut()
          .then(() => {
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
     // } else {
        // 
        //console.log()
      }
    })
  },
  
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
