//import { createStore } from 'vuex'

const state = {
    gc: null,
    result: null,
    target_date_1: null,
    target_date_2: null,
    target_date_3: null,
    target_month: null,
    max_date: null,
    min_date: new Date('2021-04-01T00:00:00'),
    time: 0,
    pos: [],
    ci: ''
}
const mutations = {
  SET_TARGET_DATE_1 (state, date) {
    state.target_date_1 = date
  },
  SET_TARGET_DATE_2 (state, date) {
    state.target_date_2 = date
  },
  SET_TARGET_DATE_3 (state, date) {
    state.target_date_3 = date
  },
  SET_TARGET_MONTH (state, date) {
    state.target_month = date
  },
  SET_MAX_DATE (state, date) {
    state.max_date = date
  },
  SET_TIME (state, time) {
    state.time = time
  },
  SET_RESULT (state, cr) {
    state.result = cr
  },
  SET_GC (state, gc) {
    state.gc = gc
  },
  SET_POS (state, ps) {
    state.pos = ps
  },
  SET_GC_CUST_INS_CODE (state, ci) {
    state.ci = ci
  },
}
const actions = {
  // '2021/04/25'をDateオブジェクト
  setTargetDateStr({ commit }, date){
    let str = date.split('/')
    let d = new Date(str.join('-') + 'T00:00:00')

    commit('SET_TARGET_DATE_1', d)
    commit('SET_TARGET_DATE_2', str.join('/'))
    commit('SET_TARGET_DATE_3', str.join(''))
    commit('SET_TARGET_MONTH', str[0] + '年' + str[1] + '月度')

    if(state.max_date == null){
      commit('SET_MAX_DATE', d)
    }else if(d > state.max_date) {
      commit('SET_MAX_DATE', d)
    }
  },
  setTargetDateObj({ commit }, date){
    commit('SET_TARGET_DATE_1', date)
    commit('SET_TARGET_DATE_2', 
      date.getFullYear()
        + '/' + ('0' + (date.getMonth() + 1)).slice(-2)
        + '/' + ('0' + date.getDate()).slice(-2)
    )
    commit('SET_TARGET_DATE_3', 
      date.getFullYear()
        + '' + ('0' + (date.getMonth() + 1)).slice(-2)
        + '' + ('0' + date.getDate()).slice(-2)
    )
    commit('SET_TARGET_MONTH', date.getFullYear() + '年' + date.getMonth() + '月度')
    if(state.max_date == null){
      commit('SET_MAX_DATE', date)
    }else if(date > state.max_date) {
      commit('SET_MAX_DATE', date)
    }
  },
  setTargetGolfCource({ commit }, gc){
    commit('SET_GC', gc)
    commit('SET_RESULT', gc.cr)
    commit('SET_POS', gc.ps)
    commit('SET_GC_CUST_INS_CODE', gc.ci)

//    commit('SET_DATE', gc.sd)
//console.log(gc)
    let date = gc.sd
    let str = date.split('/')
    let d = new Date(str.join('-') + 'T00:00:00')

    commit('SET_TARGET_DATE_1', d)
    commit('SET_TARGET_DATE_2', str.join('/'))
    commit('SET_TARGET_DATE_3', str.join(''))
    commit('SET_TARGET_MONTH', str[0] + '年' + str[1] + '月度')

    if(state.max_date == null){
      commit('SET_MAX_DATE', d)
    }else if(d > state.max_date) {
      commit('SET_MAX_DATE', d)
    }

  },
  setRainResult({ commit }, res){
    commit('SET_RESULT', res)
  },
  setRainTime({ commit }, time){
    commit('SET_TIME', time)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
