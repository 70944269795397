import Cookies from 'js-cookie'
import { store } from '@/store'
//import { useStore } from 'vuex'
import qs from 'qs'

const TokenKey = 'Admin-Token'

export function getToken() {
//  useStore()
  /* */ 
/*  let token = Cookies.get(TokenKey)
  if(token != undefined){
    let user = Cookies.get(PermKey)
    if(user != undefined && store != undefined){
      console.log('HOGE')
//      store.dispatch('user/setInfo', user)
    }
  }*/
  /* */
return Cookies.get(TokenKey)
}

export function setToken(token) {
  //Cookies.set("name", "value", { sameSite: "none" }); //none,lax,ecure
  return Cookies.set(TokenKey, token)//, { sameSite: "none" })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const PermKey = 'Perm-Token'
export function getPerm() {
  return Cookies.get(PermKey)
}

export function getName() {
  let user = qs.parse(Cookies.get(PermKey))
  if(user == undefined){
    return '';
  }else{
    //console.log(user.displayName)
    return user.displayName
  }
}
export function getEmail() {
  let user = qs.parse(Cookies.get(PermKey))
  if(user == undefined){
    return '';
  }else{
    //console.log(user.email)
    return user.email
  }
}
export function setPerm(token) {
  //console.log(token)
//  Cookies.set("name", "value", { sameSite: "none" }); //none,lax,ecure
  store.dispatch('user/setInfo', token)

         //commit('SET_EMAIL', cuu.email);
          //commit('SET_NAME', cuu.displayName)
          //commit('SET_VERIFIED', cuu.emailVerified)

  return Cookies.set(PermKey, qs.stringify(token))//, { sameSite: "none" })
}

export function removePerm() {
  return Cookies.remove(PermKey)
}
