<template>
    <router-view />
</template>

<script>
</script>

<style lang="scss">
@import './App.scss';
</style>
